.c-pill {
    align-items: center;      
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    height: 100%;
    white-space: nowrap;
    width: auto;
  
    position: relative;
    border-radius: 100px;
    line-height: 1;
    overflow: hidden;
    padding: 0px 12px 0px 20px;
    text-overflow: ellipsis;
      line-height: 1.25rem;
      color: #595959;
      
      word-break: break-word;
  
   /*  &:before {
      border-radius: 50%;
      content: '';
      height: 10px;
      left: 6px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      width: 10px;
    } */   
  }
  
  
    .c-pill--success {
      background: #b4eda0; }
      .c-pill--success:before {
        background: #6BC167; }
    .c-pill--warning {
      background: #ffebb6; }
      .c-pill--warning:before {
        background: #ffc400; }
    .c-pill--light {
      background: #e8e8e8; }
      .c-pill--light:before {
        background: #d0d0d0; }
    .c-pill--danger {
      background: #ffd5d1; }
      .c-pill--danger:before {
        background: #ff4436; }